import { useLocation } from '@reach/router';
import React from 'react';
// @ts-ignore
import { Helmet } from 'react-helmet';
import { generateFranchiseLdJsonMarkup } from '../../components/utils/franchise-ld-json-markup';
import { useDniProvider } from '../dni-provider';
import useUserIdentifier from '../../hooks/use-user-identifier';
import useLocalStorageState from '../../hooks/use-local-storage-state';
import { useTemplateProvider } from '../template-provider';
import { PageTemplate } from '../../utils/match-page-template';

const LOCAL_STORAGE_IS_MICROSITE_KEY = 'is_microsite';
const LOCAL_STORAGE_USER_ID_KEY = 'sp_visitor_id';

// TODO: Finish typing this
interface MicrositeContextReturnable {
    isMicrosite: boolean,
    isLocalCityPage: boolean,

    isHyperLocalTemplate: boolean
    isMetroTemplate: boolean
    isMetroStateTemplate: boolean

    micrositePermalink: string | null,
    franchise: Queries.Franchise | null,
    permalink: string | null,
    aboutUs: string | null,
    socialMedia: Record<string, unknown> | null,
    licenses: Record<string, unknown> | null,
    menu: Queries.Cms_franchise_primary_nav | null,
    footer: Queries.Cms_franchise_footer_nav | null,
    allowedInternalLinks: (string | null)[] | null,
    bannedInternalLinks: (string | null)[] | null,
    filteredServices: unknown,
    serviceCities: Queries.TargetCity[] | null,
    targetCity: Queries.TargetCity | null
}

const MicrositeContext = React.createContext(null as unknown as MicrositeContextReturnable);

interface MicrositeProps {
    children: React.ReactNode
    data: {
        targetCity: Queries.TargetCity
        franchise: Queries.Franchise
    }
}

const addToDataLayer = (eventName: string, key: string, value: any) => {
    // value must not be null or undefined but maybe be a boolean and be false
    if (value === null || value === undefined) {
        return;
    }
    // dataLAyer should already be initialized as both gatsby-browser and GTM conditionally initializes it
    if ('dataLayer' in window && Array.isArray(window.dataLayer)) {
        // gets the most recent item of the same event type
        const lastOfEventType = window.dataLayer.findLast(item => item.event === eventName);

        // Update only if either (a) we don't already have that event, or (b) we do, but its value for 'key' is different from 'value'
        if (!lastOfEventType || lastOfEventType[key] !== value) {
            window.dataLayer.push({
                event: eventName,
                [key]: value,

            });
        }
    }
};

const MicrositeProvider = ({ children, data = {} }: MicrositeProps): JSX.Element => {
    const location = useLocation();
    const { numberMap } = useDniProvider();

    const {
        aboutUs,
        socialMedia,
        licenses,
        allowedInternalLinks,
        bannedInternalLinks,
        filteredServices,
        menu,
        footer,
        ...franchise
    } = { ...(data?.franchise || data?.cmsMetroPage?.franchises?.[0] || {}) };

    const { template: matchedTemplate } = useTemplateProvider();

    if (franchise && numberMap && numberMap[(franchise?.yext?.mainPhone || '')]) {
        // @ts-ignore
        franchise.yext.mainPhone = numberMap[franchise.yext.mainPhone];
    }

    const isMicrosite = matchedTemplate === PageTemplate.MicrositePage || matchedTemplate === PageTemplate.MicrositeService;
    const isHyperLocalTemplate = matchedTemplate === PageTemplate.HyperLocal;
    const isMetroTemplate = matchedTemplate === PageTemplate.Metro;
    const isMetroStateTemplate = matchedTemplate === PageTemplate.MetroState;

    const {
        data: isMicrositeInLocalStorage,
        setData: setIsMicrositeInLocalStorage,
        getData: getSavedIsMicrositeIdBeforeFinishedLoading,
    } = useLocalStorageState<boolean>(
        LOCAL_STORAGE_IS_MICROSITE_KEY,
        async () => !!isMicrosite,
        [],
        null,
        () => true,
    );

    const isLocalCityPage = !!data.targetCity;
    const permalink = franchise?.permalink || '';
    const micrositePermalink = permalink;

    const targetCity = data.targetCity
        || data.franchise?.serviceCities?.[0]
        || { city: data?.franchise?.yext?.address?.city, state: data?.franchise?.yext?.address?.region };

    const providerValue = React.useMemo(() => (
        {
            franchise,
            permalink,
            aboutUs,
            socialMedia,
            licenses,
            menu,
            footer,
            allowedInternalLinks,
            bannedInternalLinks,
            filteredServices,
            serviceCities: franchise.serviceCities || [],
            isMicrosite,
            micrositePermalink,
            isLocalCityPage,
            targetCity,
            isHyperLocalTemplate,
            isMetroTemplate,
            isMetroStateTemplate,
        }
    ), [
        franchise,
        permalink,
        aboutUs,
        socialMedia,
        licenses,
        menu,
        footer,
        allowedInternalLinks,
        bannedInternalLinks,
        filteredServices,
        isMicrosite,
        micrositePermalink,
        isLocalCityPage,
        targetCity,
        isHyperLocalTemplate,
        isMetroTemplate,
        isMetroStateTemplate,
    ]);

    React.useEffect(() => {
        if (process.env.NODE_ENV === 'development') {
            console.log('FranchiseID', franchise?.franchiseNumber, providerValue);
        } else {
            window.fID = () => console.log('FranchiseID', franchise?.franchiseNumber, providerValue);
        }
        // Ignore the ESLint, we only want to log this once for devs
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, []);

    const userId = useUserIdentifier();
    const {
        data: userIdInLocalStorage,
        setData: setUserIdInLocalStorage,
        getData: getSavedUserIdBeforeFinishedLoading,
    } = useLocalStorageState<number | null>(
        LOCAL_STORAGE_USER_ID_KEY,
        async () => userId,
        [],
        null,
        () => true,
    );

    React.useEffect(() => {
        if (isMicrosite && userId && franchise?.franchiseNumber) {
            fetch(`/api/microsite?id=${userId}&franchiseNumber=${franchise.franchiseNumber}`);
        }

        // updates isMicrosite in localstorage if needed
        const isMicrositeFromLocalStorage = isMicrositeInLocalStorage || getSavedIsMicrositeIdBeforeFinishedLoading();
        if (isMicrositeFromLocalStorage !== isMicrosite) {
            setIsMicrositeInLocalStorage(!!isMicrosite);
        }

        // updates userId in localstorage if needed
        const userIdFromLocalstorage = userIdInLocalStorage || getSavedUserIdBeforeFinishedLoading();
        if (userId && userId !== userIdFromLocalstorage) {
            setUserIdInLocalStorage(userId);
        }

        // adds franchiseNumber and userId to the dataLayer
        addToDataLayer('SiteVisitorId', 'SiteVisitorId', userId);
        addToDataLayer('SiteFranchiseNumber', 'FranchiseNumber', franchise?.franchiseNumber || 3000);
    }, [userId, isMicrosite, franchise?.franchiseNumber]);

    const heroImage = {
        filename: 'servpro_vehicle.jpg',
        title: 'servpro_vehicle.jpg',
        url: 'https://images.contentstack.io/v3/assets/blt0a0cb058815d4d96/blt9af1c0e2eaba012a/servpro_vehicle.jpeg?width=1200&auto=webp',
    };

    const {
        name,
        address,
        mainPhone,
    } = franchise?.yext || {};

    return (
        <MicrositeContext.Provider value={(isMicrosite || isLocalCityPage) ? providerValue : {}}>
            <Helmet>
                <script type="application/ld+json">
                    {address
                        && generateFranchiseLdJsonMarkup({
                            name,
                            image: heroImage.url,
                            streetAddress: `${address.line1}${
                                address.line2 ? ` ${address.line2}` : ''
                            }`,
                            areaServed: franchise.geoLocation,
                            addressLocality: address.city,
                            addressRegion: address.region,
                            postalCode: address.postalCode,
                            url: `${process.env.URL || 'https://www.servpro.com'}${location.pathname}`,
                            telephone: mainPhone,
                            servicesProvided: franchise.servicesProvided,
                            ratingValue: franchise.averageRating,
                            ratingCount: franchise.reviewCount,
                            socialMedia: franchise.socialMedia,
                        })}
                </script>
            </Helmet>
            {children}
        </MicrositeContext.Provider>
    );
};

const useMicrosite = (): MicrositeContextReturnable => {
    const ctx = React.useContext(MicrositeContext);
    if (!ctx) {
        throw new Error('useMicrosite used outside of context');
    }
    return ctx;
};

// TODO: Fix these exports, MicrositeProvider should be default, don't export MicrositeContext

export { MicrositeContext, MicrositeProvider, useMicrosite };
